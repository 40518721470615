import MiniDrawer from "../../Components/Drawer";
import TableComponent from "../../Components/Table";

export default function Users() {
  return (
    <MiniDrawer>
      <TableComponent
        data={[{ id: 1, name: "teste" }]}
        columns={[
          { name: "ID", key: "id" },
          { name: "Nome", key: "name" },
        ]}
      ></TableComponent>
    </MiniDrawer>
  );
}
