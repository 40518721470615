import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../services/api";

interface DriversState {
  drivers: any[];
  driver?: any;
  veihcle?: any;
  status:
    | "idle"
    | "pending"
    | "fulfilled"
    | "rejected"
    | "fulfilledCreate"
    | "errorCreate"
    | "fulfilledDriver"
    | "errorDriver"
    | "fulfilledUpdateDriver"
    | "errorUpdateDriver"
    | "fulfilledGetVeihcle"
    | "errorGetVeihcle"
    | "fulfilledCreateVeihcle"
    | "errorCreateVeihcle"
    | "fulfilledUpdateVeihcle"
    | "errorUpdateVeihcle";
  message?: string;
  inputsErrors: {
    name: string[];
    email: string[];
    phone: string[];
    cpf: string[];
    password: string[];
    confirmPassword: string[];
  };
  inputsErrorsVeihcle: {
    id: string[];
    driver_id: string[];
    color: string[];
    model: string[];
    plate: string[];
  };
}

const initialState: DriversState = {
  drivers: [],
  driver: {},
  veihcle: {},
  status: "idle",
  inputsErrors: {
    name: [],
    email: [],
    phone: [],
    cpf: [],
    password: [],
    confirmPassword: [],
  },
  inputsErrorsVeihcle: {
    id: [],
    driver_id: [],
    color: [],
    model: [],
    plate: [],
  },
};

const DRIVERS_URL = "/admin/drivers";
const DRIVER_URL = "/admin/user";
const VEIHCLE_URL = "/admin/motorcycle";

export const getDriversService = createAsyncThunk(
  "drivers/getDrivers",
  async (_, { getState, fulfillWithValue, rejectWithValue }) => {
    const { user } = getState() as { user: { token: string } };
    const response = await api.get(DRIVERS_URL, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    if (response.status === 200) {
      return fulfillWithValue(response.data.drivers.items);
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const getDriverService = createAsyncThunk(
  "drivers/getDriver",
  async (
    data: { id: number },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    const { user } = getState() as { user: { token: string } };
    const response = await api.get(`${DRIVERS_URL}/${data.id}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    if (response.status === 200) {
      return fulfillWithValue(response.data);
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const createDriversService = createAsyncThunk(
  "drivers/createDriver",
  async (
    data: {
      name: string;
      email: string;
      phone: string;
      cpf: string;
      password: string;
    },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    const { user } = getState() as { user: { token: string } };
    let body = new FormData();
    body.append("name", data.name);
    body.append("email", data.email);
    body.append("phone", data.phone);
    body.append("cpf", data.cpf);
    body.append("password", data.password);
    body.append("driver", "1");
    const response = await api.post(DRIVER_URL, body, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    if (response.status === 200) {
      return fulfillWithValue(response.data);
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const updateDriversService = createAsyncThunk(
  "drivers/updateDriver",
  async (
    data: {
      name?: string;
      email?: string;
      phone?: string;
      cpf?: string;
      id: string;
      photo?: File;
      password?: string;
      active?: string;
    },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    const { user } = getState() as { user: { token: string } };
    let body = new FormData();
    body.append("id", data.id);
    data.name && body.append("name", data.name);
    data.email && body.append("email", data.email);
    data.phone && body.append("phone", data.phone);
    data.cpf && body.append("cpf", data.cpf);
    data.photo && body.append("photo", data.photo);
    data.password && body.append("password", data.password);
    data.active && body.append("active", data.active);

    const response = await api.post(DRIVER_URL + "?_method=PUT", body, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    if (response.status === 200) {
      return fulfillWithValue(response.data);
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const getVeihcleService = createAsyncThunk(
  "drivers/getVeihcle",
  async (
    data: { id: number },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    const { user } = getState() as { user: { token: string } };
    const response = await api.get(`${VEIHCLE_URL}/${data.id}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    if (response.status === 200) {
      return fulfillWithValue(response.data);
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const createVeihcleService = createAsyncThunk(
  "drivers/createVeihcle",
  async (
    data: { driver_id: string; model: string; plate: string; color: string },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    let body = new FormData();
    body.append("driver_id", `${data.driver_id}`)
    body.append("model", `${data.model}`)
    body.append("plate", `${data.plate}`)
    body.append("color", `${data.color}`)
    const { user } = getState() as { user: { token: string } };
    const response = await api.post(`${VEIHCLE_URL}`, body, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    if (response.status === 200) {
      return fulfillWithValue(response.data);
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const updateVeihcleService = createAsyncThunk(
  "drivers/updateVeihcle",
  async (
    data: { id: number, driver_id: number; model: string; plate: string; color: string },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    let body = new FormData();
    body.append("id", `${data.id}`)
    body.append("driver_id", `${data.driver_id}`)
    body.append("model", `${data.model}`)
    body.append("plate", `${data.plate}`)
    body.append("color", `${data.color}`)
    const { user } = getState() as { user: { token: string } };
    const response = await api.post(`${VEIHCLE_URL}?_method=PUT`, body, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    if (response.status === 200) {
      return fulfillWithValue(response.data);
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const driversSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    resetInputErrors: (state, action) => {
      state.inputsErrors = initialState.inputsErrors;
    },
    resetDriver: (state, action) => {
      state.driver = initialState.driver;
    },
    resetVeihcle: (state, action) => {
      state.driver = initialState.driver;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriversService.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        getDriversService.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.status = "fulfilled";
          state.drivers = action.payload;
        }
      )
      .addCase(getDriversService.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(createDriversService.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(createDriversService.fulfilled, (state, action) => {
        if (action.payload?.inputs_errors) {
          state.inputsErrors = action.payload?.inputs_errors;
          state.status = "errorCreate";
        } else {
          state.status = "fulfilledCreate";
        }
      })
      .addCase(createDriversService.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(getDriverService.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getDriverService.fulfilled, (state, action) => {
        if (action.payload.driver) {
          state.driver = action.payload.driver;
          state.status = "fulfilledDriver";
        } else {
          state.status = "errorDriver";
        }
      })
      .addCase(getDriverService.rejected, (state, action) => {
        state.status = "errorDriver";
      })
      .addCase(updateDriversService.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(updateDriversService.fulfilled, (state, action) => {
        if (action.payload?.inputs_errors) {
          state.inputsErrors = action.payload?.inputs_errors;
          state.status = "errorUpdateDriver";
        } else {
          state.status = "fulfilledUpdateDriver";
        }
      })
      .addCase(updateDriversService.rejected, (state, action) => {
        state.status = "errorUpdateDriver";
      })
      .addCase(getVeihcleService.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getVeihcleService.fulfilled, (state, action) => {
        state.status = "fulfilledGetVeihcle";
        if (action.payload.motorcycle) {
          state.veihcle = action.payload.motorcycle;
        } else {
          state.veihcle = {};
        }
      })
      .addCase(getVeihcleService.rejected, (state, action) => {
        state.veihcle = {};
        state.status = "errorGetVeihcle";
      })
      .addCase(createVeihcleService.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(createVeihcleService.fulfilled, (state, action) => {
        if(action.payload?.inputs_errors) {
          state.status = "errorCreateVeihcle";
          state.inputsErrorsVeihcle = action.payload.inputs_errors
        } else {
          state.status = "fulfilledCreateVeihcle";
        }
      })
      .addCase(createVeihcleService.rejected, (state, action) => {
          state.status = "errorCreateVeihcle";
      })
      .addCase(updateVeihcleService.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(updateVeihcleService.fulfilled, (state, action) => {
        if(action.payload?.inputs_errors) {
          state.status = "errorUpdateVeihcle";
          state.inputsErrorsVeihcle = action.payload.inputs_errors
        } else {
          state.status = "fulfilledUpdateVeihcle";
        }
      })
      .addCase(updateVeihcleService.rejected, (state, action) => {
          state.status = "errorUpdateVeihcle";
      })
  },
});

export const { resetInputErrors, resetVeihcle } = driversSlice.actions;
export default driversSlice.reducer;
