import React from "react";
import { Route, Routes, useLocation, Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../state/useAppSelector";

import Helper from "../Screens/Helper";
import Login from "../Screens/Login";
import Home from "../Screens/Home";
import Drivers from "../Screens/Drivers";
import Users from "../Screens/Users";
import History from "../Screens/History";

function RequireAuth({ children }: { children: JSX.Element }) {
  const selector = useAppSelector((state) => state.user);
  let location = useLocation();

  if (!selector?.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Helper />} />
      <Route path="login" element={<Login />} />
      <Route
        path="home"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        path="drivers"
        element={
          <RequireAuth>
            <Drivers />
          </RequireAuth>
        }
      />
      <Route path="users" element={<Users />} />
      <Route path="history" element={<History />} />
    </Routes>
  );
}

// export default router;
