import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../state/useAppSelector";

export default function Helper() {
  const navigate = useNavigate();
  const selector = useAppSelector(state => state.user.token)

  useEffect(() => {
    setTimeout(() => {
      if (selector) {
        navigate("home");
      } else {
        navigate("login");
      }
    }, 2000);
  }, []);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
