import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router";
import { loginService } from "../../state/users";
import { useAppSelector } from "../../state/useAppSelector";
import { useAppDispatch } from "../../state/useAppDispatch";
import { Alert, Snackbar } from "@mui/material";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://b1n.com.br/">
        b1n
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorSnack, setErrorSnack] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(selector.status === "pending");
    if (selector.status === "fulfilled") {
      if (selector.token?.length) {
        navigate("/");
      }
    } else if (selector.status === "rejected") {
      setErrorSnack(true);
      setError(selector?.error ? selector.error : "Error");
    }
  }, [selector.status]);

  const handleSubmit = () => {
    let errorE = "";
    let errorP = "";

    if (email.length) {
      if (!email.includes("@")) {
        errorE = "O email precisa ser válido";
      } else {
        errorE = "";
      }
    }
    if (password.length < 2) {
      errorP = "A senha deve conter ao menos 2 caracteres";
    } else {
      errorP = "";
    }
    setErrorEmail(errorE);
    setErrorPassword(errorP);
    if (errorE.length == 0 && errorP.length == 0) {
      dispatch(loginService({ email, password }));
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorSnack}
        onClose={() => {
          setErrorSnack(false);
        }}
        autoHideDuration={6000}
        sx={{width: "40%", height: '70px'}}
      >
        <Alert
          onClose={() => {
            setErrorSnack(false);
          }}
          severity="error"
          sx={{ width: "100%" }}

        >
          {error}
        </Alert>
      </Snackbar>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="div" sx={{ mt: 1 }}>
              <TextField
                type="email"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                error={errorEmail.length > 0}
                helperText={errorEmail}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                error={errorPassword.length > 0}
                helperText={errorPassword}
              />
              <Button
                fullWidth
                disabled={loading}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading ? "Carregando" : "Entrar"}
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    </>
  );
}
