import { useEffect, useState } from "react";
import MiniDrawer from "../../Components/Drawer";
import TableComponent from "../../Components/Table";
import { useAppSelector } from "../../state/useAppSelector";
import { useAppDispatch } from "../../state/useAppDispatch";
import {
  createDriversService,
  getDriversService,
  resetInputErrors,
  updateDriversService,
  getDriverService,
  getVeihcleService,
  createVeihcleService,
  updateVeihcleService,
  resetVeihcle,
} from "../../state/drivers";
import { Box, Button, Grid, Modal, TextField, Tooltip } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";

export default function Drivers() {
  const dispatch = useAppDispatch();
  const selectorDrivers = useAppSelector((state) => state.drivers);

  const [dataTable, setDataTable] = useState([]);
  const [open, setOpen] = useState(false);
  const [openVehicle, setOpenVehicle] = useState(false);
  const [update, setUpdate] = useState(false);
  const [driverId, setDriverId] = useState(null);
  const [dataDriver, setDataDriver] = useState<{
    name: string;
    email: string;
    phone: string;
    cpf: string;
    password: string;
    confirmPassword: string;
  }>({
    name: "",
    email: "",
    phone: "",
    cpf: "",
    password: "",
    confirmPassword: "",
  });
  const [dataVeihcle, setDataVeihcle] = useState<{
    id?: number;
    plate: string;
    color: string;
    model: string;
    driver_id?: number;
  }>({
    plate: "",
    color: "",
    model: "",
  });

  const [errorDriver, setErrorDriver] = useState<{
    name: string[];
    email: string[];
    phone: string[];
    cpf: string[];
    password: string[];
    confirmPassword: string[];
  }>({
    name: [],
    email: [],
    phone: [],
    cpf: [],
    password: [],
    confirmPassword: [],
  });

  const [errorVeihcle, setErrorVeihcle] = useState<{
    plate: string[];
    model: string[];
    color: string[];
  }>({
    plate: [],
    model: [],
    color: [],
  });

  const handleOpen = () => {
    setOpen(true);
    resetErrors();
  };

  const handleClose = () => {
    setOpen(false);
    setDataDriver({
      name: "",
      email: "",
      phone: "",
      cpf: "",
      password: "",
      confirmPassword: "",
    });
    resetErrors();
  };

  const handleCloseVehicle = () => {
    setOpenVehicle(false);
    setDataVeihcle({
      plate: "",
      color: "",
      model: "",
    });
    resetErrors();
    dispatch(resetVeihcle({}));
  };

  const tableColumns = [
    { name: "ID", key: "id" },
    { name: "Nome", key: "name" },
    { name: "Telefone", key: "phone" },
    { name: "Ativo", key: "is_active" },
  ];
  useEffect(() => {
    dispatch(getDriversService());
  }, []);

  useEffect(() => {
    if (selectorDrivers.status === "fulfilled") {
      const newDrivers: any = selectorDrivers.drivers.map((driver: any) => ({
        id: driver.id,
        name: driver.name,
        phone: driver.phone,
        is_active: driver.is_active ? "Sim" : "Não",
      }));
      setDataTable(newDrivers);
    } else if (selectorDrivers.status === "fulfilledCreate") {
      dispatch(getDriversService());
      handleClose();
    } else if (selectorDrivers.status === "fulfilledUpdateDriver") {
      dispatch(getDriversService());
      setOpen(false);
    } else if (selectorDrivers.status === "fulfilledDriver") {
      setDataDriver({
        ...selectorDrivers.driver,
        password: "",
        confirmPassword: "",
      });
      handleOpen();
    } else if (selectorDrivers.status === "fulfilledGetVeihcle") {
      setDataVeihcle({ ...selectorDrivers.veihcle });
      setOpenVehicle(true);
    } else if (selectorDrivers.status === "fulfilledCreateVeihcle") {
      dispatch(getDriversService());
      setOpenVehicle(false);
    } else if (selectorDrivers.status === "fulfilledUpdateVeihcle") {
      dispatch(getDriversService());
      setOpenVehicle(false);
    }
  }, [selectorDrivers.status]);

  useEffect(() => {
    setErrorDriver({ ...errorDriver, ...selectorDrivers.inputsErrors });
    setErrorVeihcle({
      ...errorVeihcle,
      ...selectorDrivers.inputsErrorsVeihcle,
    });
    console.log(selectorDrivers.inputsErrors);
  }, [selectorDrivers.inputsErrors, selectorDrivers.inputsErrorsVeihcle]);

  const handleCreateDriver = async () => {
    await resetErrors();
    if (dataDriver.password == dataDriver.confirmPassword) {
      await dispatch(
        createDriversService({
          name: dataDriver.name,
          email: dataDriver.email,
          cpf: dataDriver.cpf,
          phone: dataDriver.phone,
          password: dataDriver.password,
        })
      );
    } else {
      setErrorDriver({
        ...errorDriver,
        password: ["Senhas não coincidem"],
        confirmPassword: ["Senhas não coincidem"],
      });
    }
  };

  const handleUpdateDriver = async () => {
    await resetErrors();
    if (dataDriver.password == dataDriver.confirmPassword) {
      if (driverId) {
        await dispatch(
          updateDriversService({
            id: driverId,
            name:
              dataDriver.name === selectorDrivers.driver.name
                ? ""
                : dataDriver.name,
            email:
              dataDriver.email === selectorDrivers.driver.email
                ? ""
                : dataDriver.email,
            cpf:
              dataDriver.cpf === selectorDrivers.driver.cpf
                ? ""
                : dataDriver.cpf,
            phone:
              dataDriver.phone === selectorDrivers.driver.phone
                ? ""
                : dataDriver.phone,
            password:
              dataDriver.password === selectorDrivers.driver.password
                ? ""
                : dataDriver.password,
          })
        );
      }
    } else {
      setErrorDriver({
        ...errorDriver,
        password: ["Senhas não coincidem"],
        confirmPassword: ["Senhas não coincidem"],
      });
    }
  };

  const handleCreateVeihcle = async () => {
    await resetErrors();
    if (
      driverId &&
      dataVeihcle.color.length &&
      dataVeihcle.model.length &&
      dataVeihcle.plate.length
    ) {
      await dispatch(
        createVeihcleService({
          plate: dataVeihcle.plate,
          model: dataVeihcle.model,
          color: dataVeihcle.color,
          driver_id: driverId,
        })
      );
    } else {
      setErrorVeihcle({
        ...errorVeihcle,
        model: ["Preencha todos os campos"],
        plate: ["Preencha todos os campos"],
        color: ["Preencha todos os campos"],
      });
    }
  };

  const handleUpdateVeihcle = async () => {
    await resetErrors();
    if (
      driverId &&
      dataVeihcle.color.length &&
      dataVeihcle.model.length &&
      dataVeihcle.plate.length
    ) {
      await dispatch(
        updateVeihcleService({
          id: selectorDrivers.veihcle.id,
          driver_id: driverId,
          plate: dataVeihcle.plate,
          color: dataVeihcle.color,
          model: dataVeihcle.model,
        })
      );
    } else {
      setErrorVeihcle({
        ...errorVeihcle,
        model: ["Preencha todos os campos"],
        plate: ["Preencha todos os campos"],
        color: ["Preencha todos os campos"],
      });
    }
  };

  const resetErrors = async () => {
    setErrorDriver({
      name: [],
      email: [],
      phone: [],
      cpf: [],
      password: [],
      confirmPassword: [],
    });
    setErrorVeihcle({
      model: [],
      plate: [],
      color: [],
    });
    await dispatch(resetInputErrors({}));
  };

  return (
    <div>
      <MiniDrawer>
        <TableComponent
          data={dataTable}
          columns={tableColumns}
          button={{
            visible: true,
            function: () => {
              handleOpen();
              setUpdate(false);
            },
            title: "Novo motorista",
          }}
          actions={[
            {
              name: "Veículo",
              icon: (
                <Tooltip title="Veículo">
                  <TwoWheelerIcon />
                </Tooltip>
              ),
              onClick: (row) => {
                dispatch(getVeihcleService({ id: row.id }));
                resetErrors();
                setDriverId(row.id);
              },
            },
            {
              name: "Desabilitar/Habilitar",
              icon: (
                <Tooltip title="Desabilitar/Habilitar">
                  <RemoveCircleOutlineIcon />
                </Tooltip>
              ),
              onClick: (row) => {
                dispatch(
                  updateDriversService({
                    id: row.id,
                    active: row.is_active === "Sim" ? "0" : "1",
                  })
                );
              },
            },
            {
              name: "Editar",
              icon: (
                <Tooltip title="Editar">
                  <ModeEditIcon />
                </Tooltip>
              ),
              onClick: (row) => {
                setUpdate(true);
                dispatch(getDriverService({ id: row.id }));
                setDriverId(row.id);
              },
            },
          ]}
        ></TableComponent>
      </MiniDrawer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-driver-title"
        aria-describedby="modal-driver-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            bgcolor: "background.paper",
            border: "1px solid #f0f0f0",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h3 style={{ margin: 0, padding: 0, marginBottom: 30 }}>
            Novo motorista
          </h3>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                style={{ width: "100%" }}
                label="Nome"
                onChange={(t) => {
                  setDataDriver({ ...dataDriver, name: t.target.value });
                }}
                type="text"
                value={dataDriver.name}
                error={errorDriver.name.length > 0}
                helperText={
                  errorDriver.name.length > 0 ? errorDriver.name[0] : ""
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                style={{ width: "100%" }}
                label="CPF (Somente numeros)"
                inputProps={{ maxLength: 11 }}
                onChange={(e) => {
                  setDataDriver({ ...dataDriver, cpf: e.target.value });
                }}
                value={dataDriver.cpf}
                error={errorDriver.cpf.length > 0}
                helperText={
                  errorDriver.cpf.length > 0 ? errorDriver.cpf[0] : ""
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={dataDriver.phone}
                onChange={(e) => {
                  let pho = e.target.value.replace(/\D/g, "");
                  pho = pho.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
                  setDataDriver({ ...dataDriver, phone: pho });
                }}
                style={{ width: "100%" }}
                label="Telefone"
                inputProps={{ maxLength: 15 }}
                error={errorDriver.phone.length > 0}
                helperText={
                  errorDriver.phone.length > 0 ? errorDriver.phone[0] : ""
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                style={{ width: "100%" }}
                label="E-mail"
                type="email"
                value={dataDriver.email}
                onChange={(e) => {
                  setDataDriver({ ...dataDriver, email: e.target.value });
                }}
                inputProps={{ autoComplete: "off" }}
                error={errorDriver.email.length > 0}
                helperText={
                  errorDriver.email.length > 0 ? errorDriver.email[0] : ""
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                style={{ width: "100%" }}
                label="Senha"
                type="password"
                value={dataDriver.password}
                autoComplete="off"
                onChange={(e) => {
                  setDataDriver({ ...dataDriver, password: e.target.value });
                }}
                error={errorDriver.password.length > 0}
                helperText={
                  errorDriver.password.length > 0 ? errorDriver.password[0] : ""
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                style={{ width: "100%" }}
                label="Confirmar Senha"
                type="password"
                value={dataDriver.confirmPassword}
                onChange={(e) => {
                  setDataDriver({
                    ...dataDriver,
                    confirmPassword: e.target.value,
                  });
                }}
                autoComplete="off"
                error={errorDriver.confirmPassword.length > 0}
                helperText={
                  errorDriver.confirmPassword.length > 0
                    ? errorDriver.confirmPassword[0]
                    : ""
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                style={{ float: "right", width: 300 }}
                variant="outlined"
                color="success"
                onClick={() => {
                  update ? handleUpdateDriver() : handleCreateDriver();
                }}
              >
                {update ? "Atualizar" : "Cadastrar"} Motorista
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openVehicle}
        onClose={handleCloseVehicle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            bgcolor: "background.paper",
            border: "1px solid #f0f0f0",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h3 style={{ margin: 0, padding: 0, marginBottom: 30 }}>
            {dataVeihcle?.id ? "Editar" : "Novo"} veículo
          </h3>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                style={{ width: "100%" }}
                label="Placa (Ex.: ABC1234)"
                onChange={(t) => {
                  setDataVeihcle({ ...dataVeihcle, plate: t.target.value });
                }}
                type="text"
                value={dataVeihcle.plate}
                error={errorVeihcle.plate.length > 0}
                helperText={
                  errorVeihcle.plate.length > 0 ? errorVeihcle.plate[0] : ""
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                style={{ width: "100%" }}
                label="Cor (Ex.: Vermelha)"
                inputProps={{ maxLength: 11 }}
                onChange={(e) => {
                  setDataVeihcle({ ...dataVeihcle, color: e.target.value });
                }}
                value={dataVeihcle.color}
                error={errorVeihcle.color.length > 0}
                helperText={
                  errorVeihcle.color.length > 0 ? errorVeihcle.color[0] : ""
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={dataVeihcle.model}
                onChange={(e) => {
                  setDataVeihcle({ ...dataVeihcle, model: e.target.value });
                }}
                style={{ width: "100%" }}
                label="Modelo (Ex.: Honda 125cc)"
                inputProps={{ maxLength: 15 }}
                error={errorVeihcle.model.length > 0}
                helperText={
                  errorVeihcle.model.length > 0 ? errorVeihcle.model[0] : ""
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                style={{ float: "right", width: 300 }}
                variant="outlined"
                color="success"
                onClick={() => {
                  dataVeihcle?.id
                    ? handleUpdateVeihcle()
                    : handleCreateVeihcle();
                }}
              >
                {dataVeihcle?.id ? "Atualizar" : "Cadastrar"} Veículo
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
