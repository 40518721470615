import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../services/api";

export interface GraphicsSlice {
  trips: {
    success: number[];
    canceled: number[];
  };
  drivers: {
    drivers: string[];
    quantity: number[];
  };
  status: "idle" | "pending" | "fulfilled" | "rejected";
}

const DRIVERS_URL = "/admin/driverspermonth";
const TRIPS_URL = "/admin/tripspermonth";

export const driversGraphicsService = createAsyncThunk(
  "graphics/drivers",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as { user: { token: string } };
    try {
      const response = await api.get(DRIVERS_URL, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const tripsGraphicsService = createAsyncThunk(
  "graphics/trips",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as { user: { token: string } };
    try {
      const response = await api.get(TRIPS_URL, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState: GraphicsSlice = {
  drivers: {
    drivers: [],
    quantity: [],
  },
  trips: {
    success: [],
    canceled: [],
  },
  status: "idle",
};

export const graphicsSlice = createSlice({
  name: "graphics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(driversGraphicsService.pending, (state) => {
        state.status = "pending";
      })
      .addCase(driversGraphicsService.fulfilled, (state, action) => {
        state.drivers.drivers = action.payload.drivers;
        state.drivers.quantity = action.payload.quantity;
        state.status = "fulfilled";
      })
      .addCase(driversGraphicsService.rejected, (state) => {
        state.drivers.drivers = [];
        state.drivers.quantity = [];
        state.status = "rejected";
      })
      .addCase(tripsGraphicsService.pending, (state) => {
        state.status = "pending";
      })
      .addCase(tripsGraphicsService.fulfilled, (state, action) => {
        state.trips.success = action.payload.success;
        state.trips.canceled = action.payload.canceled;
        state.status = "fulfilled";
      })
      .addCase(tripsGraphicsService.rejected, (state) => {
        state.trips.success = [];
        state.trips.canceled = [];
        state.status = "rejected";
      });
  },
});

export default graphicsSlice.reducer;
