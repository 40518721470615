import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
} from "@mui/material";

interface Column {
  name: string;
  key: string;
}

interface Action {
  name: string;
  icon?: JSX.Element;
  onClick: (row: Record<string, any>) => void;
}

interface Props {
  data: Record<string, any>[];
  columns: Column[];
  rowsPerPageOptions?: number[];
  defaultRowsPerPage?: number;
  button?: {
    visible?: boolean;
    function?: any;
    title?: string;
  };
  actions?: Action[];
}

const MyTable: React.FC<Props> = ({
  data = [],
  columns = [],
  rowsPerPageOptions,
  defaultRowsPerPage = 10,
  button = { visible: false },
  actions = [],
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [search, setSearch] = useState("");

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const filteredData = data.filter((row) => {
    const values = Object.values(row).join("").toLowerCase();
    return values.includes(search.toLowerCase());
  });

  return (
    <TableContainer component={Paper}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item md={3} xs={12}>
          {button.visible && (
            <Button
              onClick={() => {
                return button.function() || null;
              }}
              style={{ margin: 10, float: "left", width: "90%", height: 40 }}
              variant="outlined"
              color="success"
            >
              {button?.title || ""}
            </Button>
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            style={{
              margin: 10,
              float: "right",
              width: "90%",
              height: 40,
            }}
            label="Pesquisar"
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.key}>{column.name}</TableCell>
              ))}
            {actions.length ? (
              <TableCell align="center" key={'action'}>Ações</TableCell>
            ) : undefined}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell key={column.key}>{row[column.key]}</TableCell>
                ))}
                {actions.length > 0 ? (
                  <TableCell align="center">
                    {actions.map((action, index) => (
                      <IconButton
                        key={index}
                        onClick={() => action.onClick(row)}
                      >
                        {action.icon ? action.icon : action.name}
                      </IconButton>
                    ))}
                  </TableCell>
                ): undefined}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
        labelRowsPerPage="Itens por linha"
      />
    </TableContainer>
  );
};

export default MyTable;
