import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../services/api";

export interface UserSlice {
  id?: number;
  name?: string;
  email?: string;
  token?: string;
  status: string;
  error?: string;
}
const LOGINURL = "/user/login";
const LOGOUTURL = "/user/logout";

export const loginService = createAsyncThunk(
  LOGINURL,
  async (
    data: { email: string; password: string },
    { fulfillWithValue, rejectWithValue, getState },
  ) => {
    // como pegar o token de dentro do estado sem precisar mandar por props
    // let userState: any = getState()
    // console.log(userState.user.token);
    let body = new FormData();
    body.append("email", data.email);
    body.append("password", data.password);
    let res = await api.post(LOGINURL, body);
    if (res.status === 200) {
      return fulfillWithValue({
        data: res.data,
      });
    } else {
      return rejectWithValue(res);
    }
  }
);

export const logoutService = createAsyncThunk(
  LOGOUTURL,
  async (data: {}, { fulfillWithValue }) => {
    return fulfillWithValue({
      data: {},
    });
  }
);

const initialState: UserSlice = {
  status: "idle",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserSlice>) => {
      state = action.payload;
    },
    logout: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginService.pending, (st) => {
        st.status = "pending";
      })
      .addCase(loginService.fulfilled, (st, action: any) => {
        if (action.payload?.data) {
          if(action.payload.data.user.is_admin === 1) {
            st.id = action.payload.data.user.id;
            st.email = action.payload.data.user.email;
            st.name = action.payload.data.user.name;
            st.token = action.payload.data.token;
            st.error = "";
          } else {
            st.status = "rejected"
            return
          }
        }
        st.status = "fulfilled";
      })
      .addCase(loginService.rejected, (st, action: any) => {
        st.error = action.payload.response.data.error;
        st.status = "rejected";
      })
      .addCase(logoutService.fulfilled, (st, action: any) => {
        st.id = undefined;
        st.email = '';
        st.name = '';
        st.token = '';
        st.error = "";
        st.status = "fulfilled";
      });
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
