import axios from "axios";
import store from "../state/store";
import { logout } from "../state/users";

const api = axios.create({
  baseURL: "https://api.azuzinho.com.br/api",
});

// api.defaults.headers.post["Content-Type"] = "multipart/form-data";
// api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// api.defaults.headers.post["Access-Control-Allow-Methods"] = "*";
// let proxy = {
//   protocol: "https",
//   host: "https://api.azuzinho.com.br/api",
//   port: 80,
// };
// api.defaults.proxy = proxy

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logout())
    }
    return error
  }
);

export default api;
