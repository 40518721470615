import MiniDrawer from "../../Components/Drawer";
import TableComponent from "../../Components/Table";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { pluginColor } from "../../Shared/pluginColor";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useAppDispatch } from "../../state/useAppDispatch";
import { useAppSelector } from "../../state/useAppSelector";
import {
  driversGraphicsService,
  tripsGraphicsService,
} from "../../state/graphics";
import { getDriversService } from "../../state/drivers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function Home() {
  const dispatch = useAppDispatch();
  const selectorGraphics = useAppSelector((state) => state.graphics);
  const selectorDrivers = useAppSelector((state) => state.drivers);

  const [dataBar, setDataBar] = useState<{
    labels: Array<string>;
    datasets: Array<{
      label: string;
      data: Array<number>;
      backgroundColor: string;
    }>;
  }>({
    labels: [],
    datasets: [],
  });

  const [dataCircle, setDataCircle] = useState<{
    labels: Array<string>;
    datasets: Array<{
      label: string;
      data: Array<number>;
      backgroundColor: Array<string>;
      borderColor: Array<string>;
      borderWidth: number;
    }>;
  }>({
    labels: [],
    datasets: [
      {
        label: "- Corridas até agora",
        data: [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [dataTable, setDataTable] = useState([]);

  const tableColumns = [
    { name: "ID", key: "id" },
    { name: "Nome", key: "name" },
    { name: "Telefone", key: "phone" },
    { name: "Ativo", key: "is_active" },
  ];

  const labels = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const optionsBar = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Corridas mensais",
      },
      customCanvasBackgroundColor: {
        color: "#212121",
      },
    },
    layout: {
      padding: 10,
    },
  };

  const optionsCircle = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Melhores Motoristas do mês",
      },
      customCanvasBackgroundColor: {
        color: "#212121",
      },
    },
    layout: {
      padding: 12,
    },
  };

  useEffect(() => {
    dispatch(driversGraphicsService());
    dispatch(tripsGraphicsService());
    dispatch(getDriversService());
  }, []);

  useEffect(() => {
    setDataBar({
      labels,
      datasets: [
        {
          label: "Finalizadas",
          data: selectorGraphics.trips.success,
          backgroundColor: "#76d21980",
        },
        {
          label: "Canceladas",
          data: selectorGraphics.trips.canceled,
          backgroundColor: "#d2191a",
        },
      ],
    });
    setDataCircle({
      labels: selectorGraphics.drivers.drivers,
      datasets: [
        { ...dataCircle.datasets[0], data: selectorGraphics.drivers.quantity },
      ],
    });
  }, [selectorGraphics.status, selectorGraphics.trips]);

  useEffect(() => {
    const newDrivers: any = selectorDrivers.drivers.map((driver: any) => ({
      id: driver.id,
      name: driver.name,
      phone: driver.phone,
      is_active: driver.is_active ? "Sim" : "Não",
    }));
    setDataTable(newDrivers);
  }, [selectorDrivers.status]);

  return (
    <MiniDrawer>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} style={{minHeight: 450}}>
          {dataBar.labels.length ? (
            <Bar
            style={{ borderRadius: 4 }}
              options={optionsBar}
              data={dataBar}
              plugins={[pluginColor]}
              updateMode="resize"
            />
          ) : undefined}
        </Grid>
        <Grid item xs={12} md={4} style={{minHeight: 450}}>
          {dataCircle.labels.length ? (
            <Doughnut
              style={{ borderRadius: 4 }}
              updateMode="resize"
              options={optionsCircle}
              data={dataCircle}
              plugins={[pluginColor]}
            />
          ) : undefined}
        </Grid>
        <Grid item xs={12} md={12}>
          {dataTable.length ? (
            <TableComponent
              data={dataTable}
              columns={tableColumns}
              defaultRowsPerPage={5}
            ></TableComponent>
          ) : undefined}
        </Grid>
      </Grid>
    </MiniDrawer>
  );
}
